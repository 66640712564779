<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.token" @change="Search" placeholder="出厂凭证"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable  @change="Search" placeholder="申请出厂类型" v-model="search.apply_type">
            <el-option label="有mac在线" :value="1"></el-option>
            <el-option label="无mac在线" :value="2"></el-option>
            <el-option label="线下自烧录" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.factory_code" @change="Search" placeholder="工厂唯一标识">
            <el-option
              v-for="item in factorylist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.product_model_code" @change="Search" placeholder="产品型号唯一标识">
            <el-option
              v-for="item in promodellist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="datevalue"
            @change="Search"
            type="daterange"
            range-separator="至"
            start-placeholder="查询申请时间开始点"
            end-placeholder="查询申请时间结束点"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="出厂方式">
        <template v-slot="scope">
          <span>{{ scope.row.apply_type==1?'有mac在线':scope.row.apply_type==2?'无mac在线':scope.row.apply_type==3?'线下自烧录':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品名称">
        <template v-slot="scope">
          <span>{{ scope.row.product_model }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号/固件版本">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code+'('+scope.row.firmware_version+')' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请工厂">
        <template v-slot="scope">
          <span>{{ scope.row.factory_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请数量/剩余数量">
        <template v-slot="scope">
          <span>{{ scope.row.num+'/'+scope.row.balance }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请备注">
        <template v-slot="scope">
          <span>{{ scope.row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请状态">
        <template v-slot="scope">
          <span>{{ ChangeStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.check_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核备注">
        <template v-slot="scope">
          <span>{{ scope.row.check_remark }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button v-if="scope.row.status==3" type="text" size="mini" @click="Edit(scope.row)">修改</el-button>
          <el-button v-if="scope.row.status==1" type="text" size="mini" @click="Check(scope.row)">审核</el-button>
          <el-button v-if="scope.row.status==2" type="text" size="mini" @click="Stop(scope.row)">停止配额</el-button>
          <el-button v-if="scope.row.status==4" type="text" size="mini" @click="Start(scope.row)">开始配额</el-button>
          <el-button v-if="scope.row.status==2||scope.row.status==4" type="text" size="mini" @click="DownData(scope.row)">下载生产资料</el-button>
          <el-button v-if="scope.row.status==7" type="text" size="mini" @click="Retry(scope.row)">重试</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.rows"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="修改/审核" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="选择状态" prop="status" label-width="100px">
          <el-select v-model="form.status">
            <el-option label="通过" :value="2"></el-option>
            <el-option label="拒绝" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核说明" label-width="100px">
          <el-input type="textarea" :rows="5" v-model="form.check_remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Editconfirm)">提交审核结果</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../axios/productionManage'
import {proStatus} from '../../assets/js/dictionary'
import util from '../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {
  },
  data () {
    return {
      datevalue: '',
      search: {
        page: 1,
        rows: 10,
        apply_type: null,
        factory_code: '',
        product_model_code: '',
        firmware_version: '',
        start_time: null,
        end_time: null,
        token: '',
      },
      applist: [],
      list: [],
      total: 0,
      factorylist: [],
      promodellist: [],
      dialogFormVisible: false,
      form: {
        token: '',
        checker: '',
        status: '',
        check_remark: '',
      },
      rules: {
        status: [{ required: true, message: '请选择审核状态', trigger: 'blur' }],
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetFactory () {
      api.FactoryList({
        data: {
          page: 1,
          rows: 100,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.factorylist = res.data.data.list
          this.GetProModel()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProModel() {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.promodellist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search() {
      this.list = []
      api.ProApplyList({
        data: {
          page: this.search.page,
          rows: this.search.rows,
          apply_type: this.search.apply_type?this.search.apply_type:0,
          factory_code: this.search.factory_code,
          product_model_code: this.search.product_model_code,
          firmware_version: this.search.firmware_version,
          start_time: this.datevalue?Date.parse(this.datevalue[0])/1000:null,
          end_time: this.datevalue?Date.parse(this.datevalue[1])/1000:null,
          token: this.search.token,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    DownData(item) {
      api.ProApplyMaterial({
        data: {
          token: item.token,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          const link = document.createElement('a');
          link.setAttribute('href', res.data.data.url)
          link.click()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Edit (item) {
      this.form = {}
      this.dialogFormVisible = true
      this.form.token = item.token
    },
    Check (item) {
      this.form = {}
      this.dialogFormVisible = true
      this.form.token = item.token
    },
    Editconfirm () {
      api.ProApplyCheck({
        data: {
          token: this.form.token,
          checker: JSON.parse(localStorage.getItem('user_info')).id,
          status: this.form.status,
          check_remark: this.form.check_remark,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.dialogFormVisible = false
          this.$message.success('提交成功')
          return
        }
        this.$message.error('提交失败' + res.data.msg)
      })
    },
    Stop (item) {
      api.ProApplyStop({
        data: {
          token: item.token,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success('停止配额成功')
          return
        }
        this.$message.error('停止配额失败' + res.data.msg)
      })
    },
    Start (item) {
      api.ProApplyRestart({
        data: {
          token: item.token,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success('开始配额成功')
          return
        }
        this.$message.error('开始配额失败' + res.data.msg)
      })
    },
    Retry (item) {
      api.ProApplyRetry({
        data: {
          token: item.token,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success('重试生成生产资料成功')
          return
        }
        this.$message.error('重试生成生产资料失败' + res.data.msg)
      })
    },
    ChangeStatus (item) {
      return util.handleValueDisplay(proStatus,item)
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.GetFactory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
